.p-statistic-container {
  @apply w-full;
}

.p-statistic-card {
  @apply bg-white p-4 shadow-xl rounded-md;
  h1 {
    @apply mb-4 text-2xl text-center;
  }
}

.p-statistic-item {
  @apply flex flex-col md:flex-row;
  h1 {
    @apply mb-0 text-xl text-left;
  }
}

.q-month-stadistics {
  @apply w-full h-96 md:w-2/4;
}

.q-month-stadistics-two {
  @apply w-full h-96 mt-8;
}
