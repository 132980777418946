.container-welcome {
  @apply px-6 py-5 bg-gray-200 h-full;

  .container-header {
    @apply flex flex-row items-center border-b border-gray-400 p-4 sm:items-end;
  }

  .img-logo {
    @apply h-16 w-24 object-contain mr-2 sm:h-28 sm:w-36;
  }

  .title {
    @apply ml-4 font-medium;
  }

  .title-bold {
    @apply font-bold;
  }

  .container-body {
    @apply mt-6;
  }

  .container-fq {
    @apply bg-white;

    &-primary {
      box-shadow: 0px 5px theme('colors.primarylogo.600');
    }

    &-secondary {
      box-shadow: 0px 5px theme('colors.secondarylogo.600');
    }

    &-tertiary {
      box-shadow: 0px 5px theme('colors.tertiarylogo.600');
    }

    .c-icon-items-w {
      @apply p-3 w-14 h-14 text-white rounded-2xl sm:pr-6;

      &-primary {
        @apply bg-primarylogo-300 bg-gradient-to-b from-primarylogo-600;
      }

      &-secondary {
        @apply bg-secondarylogo-300 bg-gradient-to-b from-secondarylogo-600;
      }

      &-tertiary {
        @apply bg-tertiarylogo-300 bg-gradient-to-b from-tertiarylogo-600;
      }
    }

    .icon-welcome {
      font-size: 2rem !important;
      line-height: 2rem !important;
    }
  }

  .arrow-collapse {
    @apply rounded-b-full w-14 flex justify-center items-center text-white;
    &-primary {
      @apply bg-primarylogo-600;
    }

    &-secondary {
      @apply bg-secondarylogo-600;
    }

    &-tertiary {
      @apply bg-tertiarylogo-600;
    }
  }

  .container-arrow-collapse {
    @apply flex justify-center items-center sm:justify-end sm:items-end sm:mr-6;
  }
}
