.checkbox-custom {
  .ant-checkbox-inner {
    border: 3px solid var(--color-gray-4);
    background-color: white;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-inner::after {
    border: 3px solid var(--color-gray-4);
    border-top: 0;
    border-left: 0;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--color-gray-4);
  }
}
