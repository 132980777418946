.droppable {
  position: absolute;
  width: 200px;
  height: 200px;
  right: 0;
  top: 10;
  background-color: #9e9e9e;
  border-left: 2px dotted red;
}

.draggable {
  width: 100px;
  height: 100px;
  background-color: yellow;
  margin: 10px auto;
}

.inProgress {
  position: absolute;
  width: 200px;
  height: 200px;
  left: 0;
  top: 10;
  background-color: #eeeeee;
  border-right: 2px dotted red;
}
