.icon-notification {
  &-success {
    @apply text-primary;
  }
}

.markdown-notification {
  &-success {
    strong {
      @apply text-primary;
    }
  }
}
