:root {
  --color-primary-200: #2d699c;
  --color-primary: #00379b;
  --color-primary-600: #2d2f9c;
  --color-primary-dark: #0c56e8;
  --color-secondary: #ecc94b;
  --color-tertiary: #6a9b3a;
  --color-blue-two: #154bae;
  --color-background-modal: rgba(0, 0, 0, 0.6);
  --color-error: #e01200;
  --color-error-shadow: rgba(224, 18, 0, 0.2);
  --color-select-bg: rgba(229, 231, 235, 0.6);
  --color-primary-logo: #c30c25;
  --color-primary-logo-300: #cc495b;
  --color-secondary-logo: #2d4584;
  --color-secondary-logo-300: #627299;
  --color-tertiary-logo: #6a9b3a;
  --color-tertiary-logo-300: #90ac73;
  --color-gray: #f9f9f9;
  --color-gray-1: #f0f0f0;
  --color-gray-2: #e3e3e3;
  --color-gray-3: #c4c4c4;
  --color-gray-4: #6f6f6f;
  --color-gray-4-border: rgba(135, 124, 122, 0.6);
  --color-alternative-one: #246c74;
  --color-alternative-two: #743766;
  --color-alternative-three: #334783;
  --color-alternative-four: #246c74;
  --color-gray-five: #6f6f6f;
  --color-alternative-three: #dd1f36;
  --color-red: #be231e;
  --color-green: #6e9b3c;
  --color-green-two: #e1fff0;
  --color-redarauco: #dd1f36;
  --color-redarauco-two: #ff98a9;
  --color-notification-lb: #dbeafe;
  --color-medium-urgency: #fbbf24;
  --color-yellow: #ad861e;
  --color-notification-yellow: #ffe8ad;
  --color-light-blue: #7096dc;
}

//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import './components/organisms/dashboard/Dashboard.scss';
@import './components/organisms/welcome/index.scss';
@import './components/atoms/index.scss';
@import './components/molecules/index.scss';
@import './styles/Markdown.scss';

/*@import '~antd/dist/antd.css';*/
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @apply text-3xl;
  @apply text-gray-800;
  @apply font-bold;
}

.title-module {
  @apply text-2xl text-gray-800 font-bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-left-color: var(--color-primary);
  animation: spin 1s ease infinite;
}

select {
  background-color: #fff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*estilos de las tablas*/
.cell-error {
  background-color: var(--color-error);
  color: #fff;
  min-width: 300px;
}

.cell-select {
  background-color: var(--color-select-bg);
  color: #000;
  min-width: 300px;
}

.cell-succes {
  background-color: var(--color-green);
  color: #fff;
}

.cell-disabled {
  background-color: var(--color-gray-2);
  color: #fff;
}

/** estilo toast */
.toast-defualt {
  border: '2px solid';
  border-color: var(--color-primary-dark);
  background-color: var(--color-primary);
  color: 'black';
}

.statistics-graph {
  height: 500px;
}

.ant-input-error {
  border-color: var(--color-error) !important;
  /*box-shadow: 0 0 0 2px var(--color-error-shadow) !important;*/
  border-width: 1px !important;
  outline: 0;
}

.ant-picker {
  border-radius: 10px;
}

.ant-tooltip-content-info {
  max-width: 650px;
}

.ant-tooltip-content-info > .ant-tooltip-content > .ant-tooltip-arrow > .ant-tooltip-arrow-content {
  @apply bg-white;
}

.ant-tooltip-content-neutral > .ant-tooltip-content > .ant-tooltip-arrow > .ant-tooltip-arrow-content {
  @apply bg-white;
}

.ant-tooltip-content-neutral > .ant-tooltip-content > .ant-tooltip-arrow > .ant-tooltip-arrow-content:before {
  background: white;
}

.ant-tooltip-content-neutral > .ant-tooltip-content > .ant-tooltip-inner {
  @apply bg-white;
}

.ant-input-error:focus {
  border-color: var(--color-error);
  box-shadow: 0 0 0 2px var(--color-error-shadow);
  border-right-width: 1px !important;
  outline: 0;
}

.ant-tooltip-content-error > .ant-tooltip-content {
  @apply text-xs font-bold text-white;
  min-height: auto;
}

.ant-tooltip-content-error > .ant-tooltip-content > .ant-tooltip-inner {
  min-height: auto;
}

.ant-tooltip-content-error > .ant-tooltip-content > .ant-tooltip-arrow > .ant-tooltip-arrow-content {
  background-color: var(--color-error);
}

.ant-tooltip-content-error > .ant-tooltip-content > .ant-tooltip-arrow > .ant-tooltip-arrow-content::before {
  background: var(--color-error);
}

.ant-tooltip-content-black > .ant-tooltip-content {
  @apply text-xs font-bold text-white;
  min-height: auto;
}

.ant-tooltip-content-black > .ant-tooltip-content > .ant-tooltip-inner {
  min-height: auto;
}

.ant-tooltip-content-black > .ant-tooltip-content > .ant-tooltip-arrow > .ant-tooltip-arrow-content {
  background-color: #000000;
}

.ant-tooltip-content-black > .ant-tooltip-content > .ant-tooltip-arrow > .ant-tooltip-arrow-content::before {
  background: #000000;
}

.ant-select-selector {
  border-radius: 10px !important;
}

.ant-select-error > .ant-select-selector {
  border-color: var(--color-error) !important;
  /*box-shadow: 0 0 0 2px var(--color-error-shadow) !important;*/
  border-width: 1px !important;
  outline: 0;
  border-radius: 10px;
}

.ant-select-error > .ant-select-selector:focus {
  border-color: var(--color-error);
  box-shadow: 0 0 0 2px var(--color-error-shadow);
  border-width: 1px !important;
  outline: 0;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  @apply bg-grayarauco-300 border-0 border-transparent text-white;
}

.background-menu-horizontal {
  background-color: rgba(0, 0, 0, 0.6);
}

.ant-btn-round.ant-btn-lg {
  padding-top: 3px;
  padding-bottom: 3px;
  height: 37.43px;
}

// animation
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke_check 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill_check 0.4s ease-in-out 0.4s forwards, scale_check 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke-width: 5;
  animation: stroke_check 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.checkmark__circle_inverse {
  stroke-dasharray: 166; /* ORIGINALLY 166px */
  stroke-dashoffset: 166; /* ORIGINALLY 166px */
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: strokeTwoCheckInverse 0.6s cubic-bezier(0.65, 0, 0.45, 1) 2 forwards reverse;
}

.checkmark_inverse {
  width: 100%; /* ORIGINALLY 56px */
  height: 100%; /* ORIGINALLY 56px */
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px 80px #7ac142;
  animation: fillCheckInverse 0.4s ease-in-out 0.4s forwards, scaleCheckInverse 0.3s ease-in-out 0.9s both;
}

.checkmark__check_inverse {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 0;
  stroke-width: 5;
  animation: strokeCheckInverse 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.1s forwards;
}

@keyframes strokeCheckInverse {
  100% {
    stroke-dashoffset: 98;
  }
}
@keyframes strokeTwoCheckInverse {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scaleCheckInverse {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fillCheckInverse {
  100% {
    box-shadow: inset 0px 0px 0px #7ac142;
  }
}

@keyframes stroke_check {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale_check {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill_check {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.tab-statistics {
  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          @apply text-xs md:text-lg font-normal;
          padding-bottom: 13px;
        }
      }
    }
  }
}

.tab-store {
  width: 100%;

  .ant-tabs-nav {
    @apply px-5 sm:px-10;
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-gray-3) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-3);
  border-radius: 20px;
  border: 0px solid transparent;
}

.ant-tab-statistcs {
  .ant-tabs-content-holder {
    background: var(--color-gray);
    padding: 16px;
  }

  .ant-tabs-nav {
    margin-bottom: 0px;

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        flex: 1;
        .ant-tabs-ink-bar {
          top: 0px;
          bottom: null;
          height: 3px;
          background: var(--color-primary);
        }
        .ant-tabs-tab {
          background: var(--color-gray);
          justify-content: center;
          display: flex;
          flex: 1;
          padding-top: 0px;
          padding-bottom: 0px;

          .ant-tabs-tab-btn {
            width: 100%;
          }
        }
        .ant-tabs-tab-active {
          background: white;
        }
      }
    }
  }
}

.ant-tab-original {
  .ant-tabs-content-holder {
    background: transparent;
    padding: 16px;
  }

  .ant-tabs-nav {
    margin-bottom: 0px;

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        flex: 1;

        .ant-tabs-ink-bar {
          top: unset;
          bottom: 0px;
          height: 3px;
          background: var(--color-primary);
        }
        .ant-tabs-tab-active {
          background: transparent;
        }
      }
    }
  }
}

.input-search {
  .ant-input-group {
    background-color: #fff;
    .ant-input-search-button {
      border: 0px;
    }
  }
}
