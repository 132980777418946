.container-fq {
  @apply p-4 shadow-md bg-white rounded-2xl;

  h2 {
    @apply font-semibold text-lg;
  }

  p {
    @apply font-normal text-base;
  }
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  .ant-collapse-header {
    padding: 0px;
  }

  margin-bottom: 0px;
  overflow: hidden;
  background: white;
  border: 0px;
  border-radius: 0px;
}
